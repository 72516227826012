<script setup lang="ts">
const config = useRuntimeConfig();
</script>

<template>
    <div class="logo">
        <a :href="config.public.logoUrl">
            <img src="/images/logo.svg">
        </a>
    </div>
</template>